var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "block" }, [
    _c(
      "div",
      { staticClass: "row", class: { "row--del": _vm.action.isAgentBlocked } },
      [
        _c("div", { staticClass: "cell cell__num" }, [
          _vm._v(_vm._s(_vm.num + 1))
        ]),
        _c("div", { staticClass: "cell cell__name" }, [
          _c("div", { staticClass: "user__icon" }, [
            _vm._v(_vm._s(_vm.action.agentName && _vm.action.agentName[0]))
          ]),
          _c("div", { staticClass: "user__name" }, [
            _vm._v(_vm._s(_vm.action.agentName))
          ])
        ]),
        _c("div", { staticClass: "cell cell__type" }, [
          _vm.action.actionType === 1
            ? _c("div", { staticClass: "cell__type-inner" }, [
                _c("i", { staticClass: "ri-home-6-fill" }),
                _c("span", [_vm._v("Показ")])
              ])
            : _vm._e(),
          _vm.action.actionType === 2
            ? _c("div", { staticClass: "cell__type-inner" }, [
                _c("i", { staticClass: "ri-eye-fill" }),
                _c("span", [_vm._v("Огляд")])
              ])
            : _vm._e(),
          _vm.action.actionType === 4
            ? _c("div", { staticClass: "cell__type-inner" }, [
                _c("i", { staticClass: "ri-phone-fill" }),
                _c("span", [_vm._v("Дзвінок")])
              ])
            : _vm._e(),
          _vm.action.actionType === 5
            ? _c("div", { staticClass: "cell__type-inner" }, [
                _c("i", { staticClass: "ri-money-dollar-box-fill" }),
                _c("span", [_vm._v("Завдаток")])
              ])
            : _vm._e(),
          _vm.action.actionType === 6
            ? _c("div", { staticClass: "cell__type-inner" }, [
                _c("i", { staticClass: "ri-government-fill" }),
                _c("span", [_vm._v("Нотаріус")])
              ])
            : _vm._e(),
          _vm.action.actionType === 7
            ? _c("div", { staticClass: "cell__type-inner" }, [
                _c("i", { staticClass: "ri-key-2-fill" }),
                _c("span", [_vm._v("Угода по оренді")])
              ])
            : _vm._e(),
          _vm.action.actionType === 8
            ? _c("div", { staticClass: "cell__type-inner" }, [
                _c("i", { staticClass: "ri-article-fill" }),
                _c("span", [_vm._v(_vm._s(_vm.action.actionSubtypeName))])
              ])
            : _vm._e(),
          _vm.action.actionType === 9
            ? _c("div", { staticClass: "cell__type-inner" }, [
                _c("i", { staticClass: "ri-advertisement-fill" }),
                _c("span", [_vm._v(_vm._s(_vm.action.actionSubtypeName))])
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "cell cell__date" }, [
          _vm._v(_vm._s(_vm._f("date")(_vm.action.actionAt)))
        ]),
        _c("div", { staticClass: "cell cell__time" }, [
          _vm._v(_vm._s(_vm.action.time))
        ]),
        _c("div", { staticClass: "cell cell__prop" }, [
          _vm.action.propertyType === 4
            ? _c("div", { staticClass: "cell__prop-inner" }, [
                _c("i", { staticClass: "ri-user-fill" }),
                _c("span", [_vm._v(_vm._s(_vm.action.propertyRelated))])
              ])
            : (_vm.action.propertyType === 2 ||
                _vm.action.propertyType === 3 ||
                _vm.action.actionType === 9) &&
              _vm.action.propertyRelated
            ? _c(
                "div",
                { staticClass: "cell__prop-inner cell-prop" },
                [
                  _c("Relation", {
                    attrs: {
                      type: "notification-icon",
                      relation: _vm.action.propertyRelated
                    }
                  }),
                  _vm.action.propertyType === 3
                    ? _c("span", [_vm._v(" iAH")])
                    : _vm._e()
                ],
                1
              )
            : _vm.action.propertyType === 2 && !_vm.action.propertyRelated
            ? _c("span", [_vm._v(" ID")])
            : _vm.action.propertyType === 3 && !_vm.action.propertyRelated
            ? _c("span", [_vm._v(" iAH")])
            : _c("span", [_vm._v("—")])
        ]),
        _c("div", { staticClass: "cell cell__buyer" }, [
          _vm.action.buyerType === 4
            ? _c("div", { staticClass: "cell__prop-inner" }, [
                _c("i", { staticClass: "ri-user-fill" }),
                _c("span", [_vm._v(_vm._s(_vm.action.buyerRelated))])
              ])
            : (_vm.action.buyerType === 2 || _vm.action.buyerType === 3) &&
              _vm.action.buyerRelated
            ? _c(
                "div",
                { staticClass: "cell__prop-inner cell-buy" },
                [
                  _c("Relation", {
                    attrs: {
                      type: "notification-icon",
                      relation: _vm.action.buyerRelated
                    }
                  }),
                  _vm.action.buyerType === 3
                    ? _c("span", [_vm._v(" iAH")])
                    : _vm._e()
                ],
                1
              )
            : _vm.action.buyerType === 2 && !_vm.action.buyerRelated
            ? _c("span", [_vm._v(" ID")])
            : _vm.action.buyerType === 3 && !_vm.action.buyerRelated
            ? _c("span", [_vm._v(" iAH")])
            : _c("span", [_vm._v("—")])
        ]),
        _c("div", { staticClass: "cell cell__result" }, [
          _vm.action.actionType === 8 || _vm.action.actionType === 9
            ? _c("div", { staticClass: "cell cell__result" }, [
                _c(
                  "div",
                  { staticClass: "indicator indicator--dot indicator--green" },
                  [_vm._v(_vm._s(_vm.action.actionNumber))]
                )
              ])
            : _c("div", [
                _vm.action.result === 1
                  ? _c(
                      "div",
                      {
                        staticClass: "indicator indicator--dot indicator--green"
                      },
                      [_vm._v("Згода")]
                    )
                  : _vm.action.result === 2
                  ? _c(
                      "div",
                      {
                        staticClass: "indicator indicator--dot indicator--red"
                      },
                      [_vm._v("Відмова")]
                    )
                  : _vm.action.result === 3
                  ? _c(
                      "div",
                      {
                        staticClass: "indicator indicator--dot indicator--blue"
                      },
                      [_vm._v("План")]
                    )
                  : _vm.action.result === 4
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "indicator indicator--dot indicator--orange"
                      },
                      [_vm._v("Думають")]
                    )
                  : _vm._e()
              ])
        ]),
        !_vm.action.isAgentBlocked
          ? _c(
              "div",
              {
                staticClass: "cell cell__name",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  }
                }
              },
              [
                _c("Multiselect", {
                  staticClass: "mSelect-dropdown",
                  attrs: {
                    options: _vm.list,
                    searchable: false,
                    "show-labels": false,
                    "reset-after": true,
                    value: _vm.item,
                    placeholder: ""
                  },
                  on: { select: _vm.actions }
                })
              ],
              1
            )
          : _vm._e()
      ]
    ),
    _c(
      "div",
      {
        staticClass: "card",
        class: { "card--del": _vm.action.isAgentBlocked }
      },
      [
        _c("div", { staticClass: "card__row" }, [
          _c("div", { staticClass: "cell cell__name" }, [
            _c("div", { staticClass: "user__icon" }, [
              _vm._v(_vm._s(_vm.action.agentName && _vm.action.agentName[0]))
            ]),
            _c("div", { staticClass: "user__name" }, [
              _vm._v(_vm._s(_vm.action.agentName))
            ])
          ]),
          _c("div", { staticClass: "cell cell__result" }, [
            _vm.action.actionType === 8 || _vm.action.actionType === 9
              ? _c("div", { staticClass: "cell cell__result" }, [
                  _c(
                    "div",
                    {
                      staticClass: "indicator indicator--dot indicator--green"
                    },
                    [_vm._v(_vm._s(_vm.action.actionNumber))]
                  )
                ])
              : _c("div", [
                  _vm.action.result === 1
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "indicator indicator--dot indicator--green"
                        },
                        [_vm._v("Згода")]
                      )
                    : _vm.action.result === 2
                    ? _c(
                        "div",
                        {
                          staticClass: "indicator indicator--dot indicator--red"
                        },
                        [_vm._v("Відмова")]
                      )
                    : _vm.action.result === 3
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "indicator indicator--dot indicator--blue"
                        },
                        [_vm._v("План")]
                      )
                    : _vm.action.result === 4
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "indicator indicator--dot indicator--orange"
                        },
                        [_vm._v("Думають")]
                      )
                    : _vm._e()
                ])
          ])
        ]),
        _vm.action.actionType === 8 || _vm.action.actionType === 9
          ? _c("div", { staticClass: "card__row" }, [
              _vm.action.actionType === 8
                ? _c("div", { staticClass: "card__cell card__cell--title" }, [
                    _vm._v("Тип продукції")
                  ])
                : _vm._e(),
              _vm.action.actionType === 9
                ? _c("div", { staticClass: "card__cell card__cell--title" }, [
                    _vm._v("Тип реклами")
                  ])
                : _vm._e(),
              _c("div", { staticClass: "cell cell__type" }, [
                _vm._v(_vm._s(_vm.actionSubtypeNameShortened))
              ])
            ])
          : _c("div", { staticClass: "card__row" }, [
              _c("div", { staticClass: "card__cell card__cell--title" }, [
                _vm._v("Тип")
              ]),
              _c("div", { staticClass: "cell cell__type" }, [
                _vm.action.actionType === 1
                  ? _c("div", { staticClass: "cell__type-inner" }, [
                      _c("i", { staticClass: "ri-home-6-fill" }),
                      _c("span", [_vm._v("Показ")])
                    ])
                  : _vm._e(),
                _vm.action.actionType === 2
                  ? _c("div", { staticClass: "cell__type-inner" }, [
                      _c("i", { staticClass: "ri-eye-fill" }),
                      _c("span", [_vm._v("Огляд/Зустріч")])
                    ])
                  : _vm._e(),
                _vm.action.actionType === 4
                  ? _c("div", { staticClass: "cell__type-inner" }, [
                      _c("i", { staticClass: "ri-phone-fill" }),
                      _c("span", [_vm._v("Дзвінок по рекламі")])
                    ])
                  : _vm._e(),
                _vm.action.actionType === 5
                  ? _c("div", { staticClass: "cell__type-inner" }, [
                      _c("i", { staticClass: "ri-money-dollar-box-fill" }),
                      _c("span", [_vm._v("Завдаток")])
                    ])
                  : _vm._e(),
                _vm.action.actionType === 6
                  ? _c("div", { staticClass: "cell__type-inner" }, [
                      _c("i", { staticClass: "ri-government-fill" }),
                      _c("span", [_vm._v("Нотаріус")])
                    ])
                  : _vm._e()
              ])
            ]),
        _c("div", { staticClass: "card__row" }, [
          _c("div", { staticClass: "card__cell card__cell--title" }, [
            _vm._v("Дата і час")
          ]),
          _c("div", { staticClass: "card__cell card__cell--datetime" }, [
            _c("i", { staticClass: "ri-calendar-fill" }),
            _c("span", { staticClass: "card__cell-date" }, [
              _vm._v(_vm._s(_vm._f("date")(_vm.action.actionAt)))
            ]),
            _c("i", { staticClass: "ri-time-fill" }),
            _c("span", { staticClass: "card__cell-time" }, [
              _vm._v(_vm._s(_vm.action.time))
            ])
          ])
        ]),
        _c("div", { staticClass: "card__row" }, [
          _c("div", { staticClass: "card__cell card__cell--title" }, [
            _vm._v("Об’єкт/Пок.")
          ]),
          _c("div", { staticClass: "card__cell--two" }, [
            _c("div", { staticClass: "cell cell__prop" }, [
              _vm.action.propertyType === 4
                ? _c("div", { staticClass: "cell__prop-inner" }, [
                    _c("i", { staticClass: "ri-user-fill" }),
                    _c("span", [_vm._v(_vm._s(_vm.action.propertyRelated))])
                  ])
                : (_vm.action.propertyType === 2 ||
                    _vm.action.propertyType === 3 ||
                    _vm.action.actionType === 9) &&
                  _vm.action.propertyRelated
                ? _c(
                    "div",
                    { staticClass: "cell__prop-inner cell-prop" },
                    [
                      _c("Relation", {
                        attrs: {
                          type: "notification-icon",
                          relation: _vm.action.propertyRelated
                        }
                      }),
                      _vm.action.propertyType === 3
                        ? _c("span", [_vm._v(" iAH")])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm.action.propertyType === 2 && !_vm.action.propertyRelated
                ? _c("span", [_vm._v(" ID")])
                : _vm.action.propertyType === 3 && !_vm.action.propertyRelated
                ? _c("span", [_vm._v(" iAH")])
                : _c("span", [_vm._v("—")])
            ]),
            _c("div", {}, [_vm._v(" / ")]),
            _c("div", { staticClass: "cell cell__buyer" }, [
              _vm.action.buyerType === 4
                ? _c("div", { staticClass: "cell__prop-inner" }, [
                    _c("i", { staticClass: "ri-user-fill" }),
                    _c("span", [_vm._v(_vm._s(_vm.action.buyerRelated))])
                  ])
                : (_vm.action.buyerType === 2 || _vm.action.buyerType === 3) &&
                  _vm.action.buyerRelated
                ? _c(
                    "div",
                    { staticClass: "cell__prop-inner cell-buy" },
                    [
                      _c("Relation", {
                        attrs: {
                          type: "notification-icon",
                          relation: _vm.action.buyerRelated
                        }
                      }),
                      _vm.action.buyerType === 3
                        ? _c(
                            "span",
                            { staticStyle: { "white-space": "pre" } },
                            [_vm._v(" iAH")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm.action.buyerType === 2 && !_vm.action.buyerRelated
                ? _c("span", { staticStyle: { "white-space": "pre" } }, [
                    _vm._v(" ID")
                  ])
                : _vm.action.buyerType === 3 && !_vm.action.buyerRelated
                ? _c("span", { staticStyle: { "white-space": "pre" } }, [
                    _vm._v(" iAH")
                  ])
                : _c("span", [_vm._v("—")])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }