<template>
  <div class="block">
    <div class="row" :class="{'row--del': action.isAgentBlocked}">
      <div class="cell cell__num">{{ num + 1 }}</div>
      <div class="cell cell__name">
        <div class="user__icon">{{ action.agentName && action.agentName[0] }}</div>
        <div class="user__name">{{ action.agentName }}</div>
      </div>
      <div class="cell cell__type">
        <div class="cell__type-inner" v-if="action.actionType === 1">
          <i class="ri-home-6-fill"></i>
          <span>Показ</span>
        </div>
        <div class="cell__type-inner" v-if="action.actionType === 2">
          <i class="ri-eye-fill"></i>
          <span>Огляд</span>
        </div>
        <div class="cell__type-inner" v-if="action.actionType === 4">
          <i class="ri-phone-fill"></i>
          <span>Дзвінок</span>
        </div>
        <div class="cell__type-inner" v-if="action.actionType === 5">
          <i class="ri-money-dollar-box-fill"></i>
          <span>Завдаток</span>
        </div>
        <div class="cell__type-inner" v-if="action.actionType === 6">
          <i class="ri-government-fill"></i>
          <span>Нотаріус</span>
        </div>
        <div class="cell__type-inner" v-if="action.actionType === 7">
          <i class="ri-key-2-fill"></i>
          <span>Угода по оренді</span>
        </div>
        <div class="cell__type-inner" v-if="action.actionType === 8">
          <i class="ri-article-fill"></i>
          <span>{{ action.actionSubtypeName }}</span>
        </div>
        <div class="cell__type-inner" v-if="action.actionType === 9">
          <i class="ri-advertisement-fill"></i>
          <span>{{ action.actionSubtypeName }}</span>
        </div>
      </div>
      <div class="cell cell__date">{{ action.actionAt | date }}</div>
      <div class="cell cell__time">{{ action.time }}</div>
      <div class="cell cell__prop">
        <div v-if="action.propertyType === 4" class="cell__prop-inner">
          <i class="ri-user-fill"></i>
          <span>{{ action.propertyRelated }}</span>
        </div>
        <div
          v-else-if="(action.propertyType === 2 || action.propertyType === 3 || action.actionType === 9) && action.propertyRelated"
          class="cell__prop-inner cell-prop"
        >
          <Relation type="notification-icon" :relation="action.propertyRelated" />
          <span v-if="action.propertyType === 3"> iAH</span>
        </div>
        <span v-else-if="action.propertyType === 2 && !action.propertyRelated"> ID</span>
        <span v-else-if="action.propertyType === 3 && !action.propertyRelated"> iAH</span>
        <span v-else>—</span>
      </div>
      <div class="cell cell__buyer">
        <div v-if="action.buyerType === 4" class="cell__prop-inner">
          <i class="ri-user-fill"></i>
          <span>{{ action.buyerRelated }}</span>
        </div>
        <div
          v-else-if="(action.buyerType === 2 || action.buyerType === 3) && action.buyerRelated"
          class="cell__prop-inner cell-buy"
        >
          <Relation type="notification-icon" :relation="action.buyerRelated" />
          <span v-if="action.buyerType === 3"> iAH</span>
        </div>
        <span v-else-if="action.buyerType === 2 && !action.buyerRelated"> ID</span>
        <span v-else-if="action.buyerType === 3 && !action.buyerRelated"> iAH</span>
        <span v-else>—</span>
      </div>
      <div class="cell cell__result">
        <div v-if="action.actionType === 8 || action.actionType === 9" class="cell cell__result">
          <div class="indicator indicator--dot indicator--green">{{ action.actionNumber }}</div>
        </div>
        <div v-else>
          <div v-if="action.result === 1" class="indicator indicator--dot indicator--green">Згода</div>
          <div v-else-if="action.result === 2" class="indicator indicator--dot indicator--red">Відмова</div>
          <div v-else-if="action.result === 3" class="indicator indicator--dot indicator--blue">План</div>
          <div v-else-if="action.result === 4" class="indicator indicator--dot indicator--orange">Думають</div>
        </div>
      </div>
      <div v-if="!action.isAgentBlocked" class="cell cell__name" @click.stop.prevent>
        <Multiselect
          class="mSelect-dropdown"
          :options="list"
          :searchable="false"
          :show-labels="false"
          :reset-after="true"
          :value="item"
          placeholder
          @select="actions"
        />
      </div>
    </div>
    <div class="card" :class="{'card--del': action.isAgentBlocked}">
      <div class="card__row">
        <div class="cell cell__name">
          <div class="user__icon">{{ action.agentName && action.agentName[0] }}</div>
          <div class="user__name">{{ action.agentName }}</div>
        </div>
        <div class="cell cell__result">
          <div v-if="action.actionType === 8 || action.actionType === 9" class="cell cell__result">
            <div class="indicator indicator--dot indicator--green">{{ action.actionNumber }}</div>
          </div>
          <div v-else>
            <div v-if="action.result === 1" class="indicator indicator--dot indicator--green">Згода</div>
            <div v-else-if="action.result === 2" class="indicator indicator--dot indicator--red">Відмова</div>
            <div v-else-if="action.result === 3" class="indicator indicator--dot indicator--blue">План</div>
            <div v-else-if="action.result === 4" class="indicator indicator--dot indicator--orange">Думають</div>
          </div>
        </div>
      </div>
      <div v-if="action.actionType === 8 || action.actionType === 9" class="card__row">
        <div v-if="action.actionType === 8" class="card__cell card__cell--title">Тип продукції</div>
        <div v-if="action.actionType === 9" class="card__cell card__cell--title">Тип реклами</div>
        <div class="cell cell__type">{{ actionSubtypeNameShortened }}</div>
      </div>
      <div v-else class="card__row">
        <div class="card__cell card__cell--title">Тип</div>
        <div class="cell cell__type">
          <div class="cell__type-inner" v-if="action.actionType === 1">
            <i class="ri-home-6-fill"></i>
            <span>Показ</span>
          </div>
          <div class="cell__type-inner" v-if="action.actionType === 2">
            <i class="ri-eye-fill"></i>
            <span>Огляд/Зустріч</span>
          </div>
          <div class="cell__type-inner" v-if="action.actionType === 4">
            <i class="ri-phone-fill"></i>
            <span>Дзвінок по рекламі</span>
          </div>
          <div class="cell__type-inner" v-if="action.actionType === 5">
            <i class="ri-money-dollar-box-fill"></i>
            <span>Завдаток</span>
          </div>
          <div class="cell__type-inner" v-if="action.actionType === 6">
            <i class="ri-government-fill"></i>
            <span>Нотаріус</span>
          </div>
        </div>
      </div>
      <div class="card__row">
        <div class="card__cell card__cell--title">Дата і час</div>
        <div class="card__cell card__cell--datetime">
          <i class="ri-calendar-fill"></i>
          <span class="card__cell-date">{{ action.actionAt | date }}</span>
          <i class="ri-time-fill"></i>
          <span class="card__cell-time">{{ action.time }}</span>
        </div>
      </div>
      <div class="card__row">
        <div class="card__cell card__cell--title">Об’єкт/Пок.</div>
        <div class="card__cell--two">
          <div class="cell cell__prop">
            <div v-if="action.propertyType === 4" class="cell__prop-inner">
              <i class="ri-user-fill"></i>
              <span>{{ action.propertyRelated }}</span>
            </div>
            <div
              v-else-if="(action.propertyType === 2 || action.propertyType === 3 || action.actionType === 9) && action.propertyRelated"
              class="cell__prop-inner cell-prop"
            >
              <Relation type="notification-icon" :relation="action.propertyRelated" />
              <span v-if="action.propertyType === 3"> iAH</span>
            </div>
            <span v-else-if="action.propertyType === 2 && !action.propertyRelated"> ID</span>
            <span v-else-if="action.propertyType === 3 && !action.propertyRelated"> iAH</span>
            <span v-else>—</span>
          </div>
          <div class=""> / </div>
          <div class="cell cell__buyer">
            <div v-if="action.buyerType === 4" class="cell__prop-inner">
              <i class="ri-user-fill"></i>
              <span>{{ action.buyerRelated }}</span>
            </div>
            <div
              v-else-if="(action.buyerType === 2 || action.buyerType === 3) && action.buyerRelated"
              class="cell__prop-inner cell-buy"
            >
              <Relation type="notification-icon" :relation="action.buyerRelated" />
              <span style="white-space: pre" v-if="action.buyerType === 3"> iAH</span>
            </div>
            <span style="white-space: pre" v-else-if="action.buyerType === 2 && !action.buyerRelated"> ID</span>
            <span style="white-space: pre" v-else-if="action.buyerType === 3 && !action.buyerRelated"> iAH</span>
            <span v-else>—</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ModalUploadAction from '@/views/Actions/UploadAction'
import Relation from '@/components/Relation'

export default {
  name: 'Action',
  props: ['action', 'num'],
  components: {
    Multiselect,
    Relation,
  },
  computed: {
    actionSubtypeNameShortened() {
      return this.action.actionSubtypeName?.replace('Повідомлення', 'Повід.')
    }
  },
  data: () => ({
    list: ['Редагувати', 'Видалити'],
    item: ''
  }),
  methods: {
    actions(option) {
      if (option === 'Редагувати') {
        const style = this.$modal.styles
        this.$modal.show(
          ModalUploadAction,
          {action: this.action},
          {...style, name: 'modalUploadAction'},
          {'before-open': this.$modal.open, 'before-close': this.$modal.close}
        )
      } else if (option === 'Видалити') {
        this.$store.dispatch('delActions', this.action.id)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
